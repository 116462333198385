// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	// console.log('bas js loaded');
	$('.bas-wrapper .flexslider').flexslider({
		animation: "fade",
		prevText: "",
		nextText: "",
		controlNav: true,
		directionNav: true,
		slideshow: false,
	});
}); /* end of as page load scripts */